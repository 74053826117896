export const getParams = function(lang, skip, top, sort, sortDesc, filter) {
  let params = {}
  if (lang && typeof(lang) === 'string') {
    params._locale = lang
  }
  else if (lang) {
    params = lang
  }
  if (typeof(skip) === 'number') {
    params._start = skip.toString()
  }
  if (typeof(top) === 'number') {
    params._limit = top.toString()
  }
  if (sort) {
    let sortToUse = sort
    let sortDescToUse = sortDesc
    if (!Array.isArray(sortToUse)) {
      sortToUse = [sortToUse]
      sortDescToUse = [sortDesc]
    }
    params._sort = sortToUse.map((f, i) => `${f}:${sortDescToUse[i] ? 'desc' : 'asc'}`).join(',')
  }
  if (filter) {
    params = {...params, ...constructStrapiFilter(filter)}
  }
  return params
}

export const constructStrapiFilter = function(filter) {
  if (typeof(filter) === 'object' && typeof(filter.length) === 'undefined') {
    if (Object.prototype.toString.call(filter.value) === '[object Date]') {
      filter.value = filter.value.toISOString()
    }
    return filter.operator ? {[filter.field + '_' + filter.operator]: filter.value} : {[filter.field]: filter.value}
  } else if (typeof(filter) === 'object') {
    return filter.reduce((params, currentFilter) => { return {...params, ...constructStrapiFilter(currentFilter)}}, {})
  }
}
